<template>
  <div class="footer">
    <div class="footer-top">
      <div class="footer-top-wrap">
        <div class="logo-img">
          <router-link to="/">
            <img src="https://gwcdn.fuhua95.com/pc/footer-logo.png" alt />
          </router-link>
        </div>
        <div class="footer-menu-box">
          <!-- <div class="menu-item">
            <div class="item-tit"><router-link to="/">首页</router-link></div>
          </div> -->
          <div class="menu-item">
            <div class="item-tit"><a href="https://shop.fuhua52.com/" target="_blank">周边手办</a></div>
          </div>
          <div class="menu-item">
            <div class="item-tit">产品中心</div>
            <div class="item-menu"  @click="jumpPage('/dapaidui')">宇宙大派队</div>
            <!-- <div class="item-menu"  @click="jumpPage('/jiaosu')">脚速旅行</div> -->
            <div class="item-menu"  @click="jumpPage('/fengchao')">源之蜂巢数据库</div>
            
            <div class="item-menu"  @click="jumpPage('/longxiang')">龍翔手游</div>
          </div>
          <div class="menu-item">
            <div class="item-tit">联系我们</div>
            <div class="item-menu" @click="jumpPage('/joinUs',1)">联系我们</div>
            <div class="item-menu" @click="jumpPage('/joinUs',2)">社会招聘</div>
            <div class="item-menu" @click="jumpPage('/joinUs',3)">校园招聘</div>
            
          </div>
          <div class="menu-item">
            <div class="item-tit">集团概述</div>
            <div  class="item-menu" @click="jumpPage('/about',1)">集团介绍</div>
            <div  class="item-menu" @click="jumpPage('/about',2)">集团文化</div>
            <div  class="item-menu" @click="jumpPage('/about',3)">集团高管</div>
          </div>
          
          <div class="menu-item">
            <div class="item-tit">集团动态</div>
            <div class="item-menu" @click="jumpPage('/groupDynamics',1)">新闻动态</div>
            <div class="item-menu" @click="jumpPage('/groupDynamics',2)">产品动态</div>
            <div class="item-menu" @click="jumpPage('/groupDynamics',3)">政府走访</div>
            <div class="item-menu" @click="jumpPage('/groupDynamics',4)">校企合作</div>
          </div>
          
          
        </div>
        
      </div>
    </div>
    <div class="footer-bottom">
      <div class="footer-bottom-wrap">
        <p>
          <span>闽网文 (2022) 0947-045号</span>
          <b>|</b>
          <span>ICP证:闽B2-20230010</span>
          <b>|</b>
          <span><a href="https://beian.miit.gov.cn/" target="_blank">闽ICP备2022015372号-2</a></span>
        </p>
        <p>
          <span>福建源之宇宙文化传播有限公司版权所有©2018-2023。</span>
          <b>|</b>
          <span>地址：福建省泉州市丰泽区东海街道滨海社区大兴街72号菲莉中心1701-1706室</span>
        </p>
        <p>
          <span>健康游戏忠告：抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</span>
        </p>
        <p>
          <span>亲爱的市民朋友，福建警方反诈劝阻电话“96110”系专门针对避免您财产被骗受损而设，请您一旦收到来电，立即接听。</span>
        </p>
        <p>
          <span>违法和不良信息举报电话:4009936669</span>
          <b>|</b>
          <span>文化部网络游戏举报和联系电子邮箱：wlyxjb@gmail.com</span>
          <b>|</b>
          <span>扫黄打非举报</span>
        </p>
        <div class="bottom-line">
          <!-- <div class="line-item">
            <img src="../assets/images/wenhua.png" alt="">
            <span>互联网文化经营单位</span>
          </div> -->
          <div class="line-item">
            <a href="https://www.12377.cn/" target="_blank">
              <img src="../assets/images/youhaijubao.png" alt="">
              <span>网上有害信息举报专区</span>
            </a>
            
          </div>
          <div class="line-item">
            <a href="http://jubao.fjsen.com" target="_blank">
              <img src="../assets/images/b-jubao.png" alt="">
              <span>福建省互联网违法和<br />不良信息举报中心</span>
            </a>
            
          </div>
          <!-- <div class="line-item">
            <img src="../assets/images/wangan.png" alt="">
            <span>闽公安备案<br />31010402004903号</span>
          </div> -->
          <div class="line-item">
            <a href="https://www.beian.gov.cn/" target="_blank">
              <img src="../assets/images/wenhua.png" alt="">
              <span>福建警方反诈劝阻电话<br />96110</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { getCurrentInstance, ref, reactive, defineComponent, toRefs, onBeforeMount,onMounted } from 'vue';
import moment from "moment"; 
import homeApi from '../api/api.js'
import { useRoute,useRouter } from 'vue-router'
import Bus from '../common/bus'
export default {
  setup() {
    const router = useRouter();
    const jumpPage = (path,tabIndex) => {
      document.body.scrollIntoView()
      if(tabIndex){
        
        console.log(window.location.hash)
        if(window.location.hash.indexOf(path)>-1){
          console.log("当前页")
          Bus.$emit('changeTabIndex',{tabIndex:tabIndex-1});
        }else{
          router.push({
              path: path,
              query:{
                tabIndex:tabIndex-1
              }
          });
        }
       
      }else{
        router.push({
            path: path,
        });
      }
    }

    return {
      jumpPage
    }
  },

  name: "footers"
};
</script>

<style scoped lang="scss">
.footer {
  .footer-top {
    display: flex;
    width: 100%;
    height: 400px;
    background: #292C3D;
    border-bottom: 1px solid #66697B;
    .footer-top-wrap {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: space-between;
    }
    .logo-img {
      width: 192px;
      height: 147px;
      margin-top: 84px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .footer-menu-box {
      display: flex;
      margin-top: 84px;
      .menu-item {
        display: flex;
        flex-direction: column;
        margin-right: 50px;
        .item-tit {
          font-size: 20px;
          font-family: "微软雅黑";
          color: #CFD5E9;
          margin-bottom: 28px;
          font-weight: 600;
          a{
            color: #CFD5E9;
            text-decoration: none;
          }
          a:hover{
            text-decoration: underline;
          }
        }
        .item-menu {
          font-size: 20px;
          font-family: "微软雅黑";
          font-weight: 400;
          color: #CFD5E9;
          margin-bottom: 28px;
          cursor: pointer;
          text-decoration: none;
          
        }
        .item-menu:hover{
            text-decoration: underline;
        }
      }
    }
  }
  .footer-bottom {
    width: 100%;
    background: #292C3D;
    .footer-bottom-wrap {
      width: 1200px;
      margin: 0 auto;
      font-size: 20px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #cfd6e9;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      a{
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #66697B;
        text-decoration: none;
        cursor: pointer;
      }
      p{
        display: flex;
        align-content: center;
        font-size: 12px;
        color: #66697B;
        margin-bottom: 10px;
        span{
          font-size: 12px;
          color: #66697B;
        }
        b{
          margin: 0 10px;
        }
      }
      .bottom-line{
        display: flex;
        align-items: center;
        justify-content: space-around;
        .line-item{
          display: flex;
          align-items: center;
          margin-right: 40px;
          a{
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #66697B;
            text-decoration: none;
          }
          img{
            width: 30px;
            height: 30px;
            margin-right: 20px;
          }
          span{
            font-size: 12px;
            color: #66697B;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>